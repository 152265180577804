import { ProviderFactory } from '../provider-factory/provider-factory';
import { ChainId } from '../types';

export abstract class BasePlatform
{
    protected chainId: ChainId;
    protected providerFactory: ProviderFactory;

    constructor(
        chainId_: ChainId,
        providerFactory_: ProviderFactory
    )
    {
        this.chainId = chainId_;
        this.providerFactory = providerFactory_;
    }

    public abstract balanceCoin(_address: string, _chainId?: ChainId): Promise<string>;
    public abstract balanceToken(_walletAddress: string, _address: string, _abi: any, _chainId?: ChainId): Promise<string>;
    public abstract prepareTransaction<T>(_data: T, address: string, _chainId?: ChainId): Promise<T>;
    public abstract waitTxConfirmation(_hash: string, address: string, _confirmations: number, _chainId?: ChainId): Promise<string>;
    public abstract getAllowance(walletAddress_: string, address_: string, addressTo_: string, abi_: any, _chainId?: ChainId): Promise<string>;
    public abstract approve(amount_: string, walletAddress_: string, address_: string, addressTo_: string, abi_: any, _chainId?: ChainId): Promise<any>;
    public abstract estimateTransactionFee<T>(_data: T, address: string, _chainId?: ChainId): Promise<string>;
}
