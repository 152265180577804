import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { eFlowMode, eWalletGetMode } from '@common/api/auth';
import { WalletService } from '@services/wallet/wallet.service';
import { ethers } from 'ethers';
import { Observable, ReplaySubject, filter, firstValueFrom, fromEvent } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { BLOCKCHAIN_SCANS } from './config';
import { BasePlatform } from './platform-connectors/base-platform';
import { EtheriumPlatform } from './platform-connectors/ethereum-platform';
import { ProviderFactory } from './provider-factory/provider-factory';
import { ChainId } from './types';
import { shortAddress } from './utils';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class BlockchainProvidersService {
    private platformConnector!: BasePlatform | undefined;
    private walletAddress!: string | undefined;
    private chainId: ChainId = ChainId.ETHEREUM_MAINNET;
    private chainIdSubject: ReplaySubject<ChainId> = new ReplaySubject(1);
    private connectionStateSubject: ReplaySubject<boolean> = new ReplaySubject(1);

    private readonly blockchainScans = BLOCKCHAIN_SCANS;

    constructor(
        private providerFactory: ProviderFactory,
        private auth: AuthService,
        private walletService: WalletService,
        @Inject(DOCUMENT) private document: Document,
        private route: ActivatedRoute,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        if (isPlatformBrowser(this.platformId))
        {
            this.chainIdSubject.subscribe((chainId) => {
                if (this.walletAddress) {
                    this.connect();
                }
            });
    
            this.auth.authState$.subscribe(
                async (state) => {
                    if (!state) {
                        this.walletAddress = undefined;
                        this.disconnect();
                        this.connectionStateSubject.next(false);
                        return;
                    }
    
                    const routeWallet = this.route.snapshot.queryParamMap.get('wallet');
                    this.walletAddress = routeWallet ? routeWallet : await this.auth.UserInfo!.wallet.address;
                    this.connect();
                    this.connectionStateSubject.next(true);
                    return;
                }
            );
        }
    }

    //#region public getters
    public get chainIdState(): Observable<ChainId> {
        return this.chainIdSubject.asObservable();
    }

    public get connectionState(): Observable<boolean> {
        return this.connectionStateSubject.asObservable();
    }

    public get WalletAddress(): string | undefined {
        return this.walletAddress;
    }

    public get ChainId(): ChainId {
        return this.chainId;
    }
    //#endregion public getters

    //#region public methods
    public async sendTransaction(_data: any, _chainId?: ChainId): Promise<any> {
        await this.auth.check();

        if (!this.auth.LoginState) {
            return await this.auth.logout();
        }

        const tx = await (<BasePlatform>this.platformConnector).prepareTransaction(
            _data,
            <string>this.walletAddress,
            _chainId
        );

        return await this.sendTxToHid(tx);
    }

    public async signMessage(data: string, arrayify: boolean = false) {
        await this.auth.check();

        if (!this.auth.LoginState) {
            return await this.auth.logout();
        }

        return await this.sendSignToHid(data, arrayify);
    }

    public async estimateTransationFee(_data: any, _chainId?: ChainId): Promise<string> {
        const result = (<BasePlatform>this.platformConnector).estimateTransactionFee(
            _data,
            <string>this.walletAddress,
            _chainId
        );

        return result;
    }

    public async balanceCoin(_chainId?: ChainId): Promise<string> {
        return (<BasePlatform>this.platformConnector).balanceCoin(<string>this.walletAddress, _chainId);
    }

    public async balanceToken(_address: string, _abi: any, _chainId?: ChainId): Promise<string> {
        return (<BasePlatform>this.platformConnector).balanceToken(
            <string>this.walletAddress,
            _address,
            _abi,
            _chainId
        );
    }

    public async waitTxConfirmation(
        _hash: string,
        chainId: ChainId,
        _confirmations = 3,
    ): Promise<string> {
        if (!chainId && (<BasePlatform>this.platformConnector)['waitTxConfirmation']) {
            return await (<BasePlatform>this.platformConnector)['waitTxConfirmation'](
                _hash,
                <string>this.walletAddress,
                _confirmations,
                chainId
            );
        }

        if (!!chainId) {
            const provider = await this.providerFactory.getProvider(chainId);
            await provider.waitForTransaction(_hash, _confirmations);
        }

        return _hash;
    }

    public async getAllowance(
        address_: string,
        addressTo_: string,
        abi_: any, 
        _chainId?: ChainId
    ): Promise<string> {
        const allowance = await (<BasePlatform>this.platformConnector).getAllowance(
            <string>this.walletAddress,
            address_,
            addressTo_,
            abi_,
            _chainId
        );

        return allowance;
    }

    public async approve(
        amount_: string,
        address_: string,
        addressTo_: string,
        abi_: any, 
        _chainId?: ChainId
    ): Promise<any> {
        const tx = await (<BasePlatform>this.platformConnector).approve(
            amount_,
            <string>this.walletAddress,
            address_,
            addressTo_,
            abi_,
            _chainId
        );

        return await this.sendTransaction(tx);
    }

    public async getApproveTx(
        amount_: string,
        address_: string,
        addressTo_: string,
        abi_: any, 
        _chainId?: ChainId
    ): Promise<any> {
        const tx = await (<BasePlatform>this.platformConnector).approve(
            amount_,
            <string>this.walletAddress,
            address_,
            addressTo_,
            abi_,
            _chainId
        );

        return tx;
    }

    public async cancelApprove(
        address_: string,
        addressTo_: string,
        abi_: any
    ): Promise<any> {
        const tx = await (<BasePlatform>this.platformConnector).approve(
            '0',
            <string>this.walletAddress,
            address_,
            addressTo_,
            abi_
        );

        return await this.sendTransaction(tx);
    }

    public setChainId(_userChainId: ChainId): void {
        if (this.chainId !== _userChainId) {
            this.chainId = _userChainId;
            this.chainIdSubject.next(this.chainId);

            try {
                if (isPlatformBrowser(this.platformId)) {
                    localStorage.setItem('chain_id', this.chainId.toString());
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    public composeTxLinkToBlockchainScan(_txHash: string, _chainId: ChainId): string {
        return `${this.blockchainScans[_chainId]}/tx/${_txHash}`;
    }

    public parseChainError(error: any): string {
        if (error?.reason) {
            return error.reason[0].toUpperCase() + error.reason.slice(1);
        }

        const errorMessage = error.message;

        if (typeof errorMessage === 'string') {
            return errorMessage;
        }

        return `Blockchain reverted transaction for ${shortAddress(this.walletAddress as string)}`;
    }
    //#endregion public methods

    private connect() {
        this.platformConnector = new EtheriumPlatform(
            this.chainId,
            this.providerFactory,
        );
    }

    public async disconnect(): Promise<void> {
        this.platformConnector = undefined;
    }

    private async sendTxToHid(_tx: any) {
        console.log("private async sendTxToHid(_tx: any) {");
        
        const tx: any = _tx.value === "0" ?
            {
                data: _tx.data,
                to: _tx.to,
                from: _tx.from,
                chain: _tx.chainId.toString(),
            } :
            {
                data: _tx.data,
                to: _tx.to,
                from: _tx.from,
                chain: _tx.chainId,
                value: `0x${Number(_tx.value).toString(16)}`
            };

        localStorage.setItem('hid-tx-chain', _tx.chainId.toString());

        const gas = _tx.gasLimit || _tx.gas;
        if (gas) {
            tx.gas = ethers.BigNumber.from(gas).toHexString();
        }

        if (_tx.nonce) {
            tx.nonce = ethers.BigNumber.from(_tx.nonce).toHexString();
        }

        console.log(tx);

        const loginLink = await this.auth.getLoginUrl(
            location.href,
            eFlowMode.TRANSACTION,
            eWalletGetMode.WALLET_GET_FAST
        );

        const url = new URL(loginLink);

        url.searchParams.set("transaction", JSON.stringify(tx));

        const frameBackdrop = this.createIframe(url);

        const listener = (event: any) => {
            const data = event.data;

            if (data === 'hyperid-need-user-interaction') {
                frameBackdrop.classList.add("show");
            }

            if (data === 'hid-no-need-interaction') {
                frameBackdrop.classList.remove("show");
            }

            if (data.action === "close-window" && frameBackdrop) {
                try {
                    this.document.body.removeChild(frameBackdrop);
                }
                catch (e) {

                }
                event.stopPropagation();
            }
        };

        window.addEventListener('message', listener);

        const resultMessage = await firstValueFrom(
            fromEvent(window, "message").pipe(filter((event: any) => event.data.name === "hid-tx-parsed"))
        );

        if (!resultMessage.data?.data || resultMessage.data?.data.status === "FAILED") {
            return null;
        }

        return resultMessage.data.data;
    }

    private async sendSignToHid(msg: string, arrayify: boolean = false) {
        const wallet_data_to_sign: any = {
            data: msg,
            wallet_address: this.walletAddress
        };

        const loginLink = await this.auth.getLoginUrl();

        const url = new URL(loginLink);

        if (arrayify) {
            wallet_data_to_sign.arrayify = true;
            url.searchParams.set("arrayify", 'true');
        }

        url.searchParams.set("wallet_data_to_sign", JSON.stringify(wallet_data_to_sign));
        const frameBackdrop = this.createIframe(url);

        const listener = (event: any) => {
            const data = event.data;
            console.log("sign flow:", data);

            if (data === 'hyperid-need-user-interaction') {
                frameBackdrop.classList.add("show");
            }

            if (data === 'hid-no-need-interaction') {
                frameBackdrop.classList.remove("show");
            }

            if (data.action === "close-window" && frameBackdrop) {
                this.document.body.removeChild(frameBackdrop);
            }
            event.stopPropagation();
        };

        window.addEventListener('message', listener);

        const resultMessage = await firstValueFrom(
            fromEvent(window, "message").pipe(filter((event: any) => event.data.name === "sign-result"))
        );
        window.removeEventListener('message', listener);

        if (!resultMessage.data?.data || resultMessage.data?.data.status === "FAILED") {
            return null;
        }

        return resultMessage.data.data;
    }


    private createIframe(url: URL): HTMLDivElement {
        url.searchParams.append("uidisplay", 'popup');
        url.searchParams.append("login_hint", this.auth.UserInfo!.email);
        url.searchParams.append("is_login_hint_required", '1');
        const hidForm = document.createElement("form");
        const flowFrame = this.document.createElement("iframe");
        hidForm.setAttribute("method", "post");
        hidForm.setAttribute("action", url.origin + url.pathname);
        hidForm.setAttribute("target", "flow-frame");
        flowFrame.src = url.href.toString();
        console.log('URL size', new Blob([url.href]).size);
        flowFrame.setAttribute("allow", "clipboard-write;");

        for (const [key, value] of url.searchParams) {
            const input = document.createElement("input");
            input.type = "text";
            input.name = key;
            input.value = value;

            hidForm.appendChild(input);
        }

        const frameBackdrop = this.document.createElement("div");
        frameBackdrop.classList.add("flow-frame-backdrop");
        frameBackdrop.appendChild(flowFrame);
        this.document.body.appendChild(hidForm);
        flowFrame.name = "flow-frame";
        flowFrame.id = "flow-frame";
        flowFrame.classList.add("flow-frame");
        this.document.body.appendChild(frameBackdrop);

        hidForm.submit();
        this.document.body.removeChild(hidForm);

        return frameBackdrop;
    }
}