import { BigNumber, Contract, ethers, providers, utils, VoidSigner } from 'ethers';

import { ProviderFactory } from '../provider-factory/provider-factory';
import { ChainId, EthersProvider } from '../types';
import { sleep } from '../utils';
import { BasePlatform } from './base-platform';

export class EtheriumPlatform extends BasePlatform
{
    private provider: providers.JsonRpcProvider | providers.FallbackProvider;

    constructor(
        chainId_: ChainId,
        providerFactory_: ProviderFactory,
    )
    {
        super(chainId_, providerFactory_);

        this.provider = this.providerFactory.getProvider(this.chainId) as EthersProvider;

        this.providerFactory.providersState.subscribe(() =>
        {
            this.provider = this.providerFactory.getProvider(this.chainId) as EthersProvider;
        });
    }

    //#region Public Methods
    public async balanceCoin(_walletAddress: string, _chainId?: ChainId): Promise<string>
    {
        const provider = _chainId ? this.providerFactory.getProvider(_chainId) : this.provider;
        const balance = await provider.getBalance(_walletAddress);

        return utils.formatEther(balance);
    }

    public async balanceToken(_walletAddress: string, _tokenAddress: string, _abi: any, _chainId?: ChainId): Promise<string>
    {
        const provider = _chainId ? this.providerFactory.getProvider(_chainId) : this.provider;
        let contract = new Contract(_tokenAddress, _abi, provider);

        const balance = await contract[ 'balanceOf(address)' ](_walletAddress);

        let decimals = 18;

        try
        {
            decimals = await contract[ 'decimals' ]();
        }
        catch (e) { }

        return utils.formatUnits(balance, decimals);
    }

    public async prepareTransaction(_data: any, address: string): Promise<any>
    {
        console.log(_data);

        let gasLimit = _data.gasLimit;

        if (!!_data.gasLimit.type && _data.gasLimit.type === 'BigNumber')
        {
            gasLimit = utils.formatUnits(_data.gasLimit);
        }

        const tx = {
            from: _data.from || address,
            to: _data.to,
            data: _data.data,
            gas: Number(gasLimit),
            // gasPrice: Number(_data.gasPrice),
            value: (_data.value || 0).toString(),
            nonce: _data.nonce,
            chainId: _data.chainId,
        };

        return tx;
    }

    public async estimateTransactionFee(_data: any, address: string, _chainId?: ChainId): Promise<string>
    {
        const provider = _chainId ? this.providerFactory.getProvider(_chainId) : this.provider;
        let gasLimit = _data.gasLimit;
        let value = _data.value;

        if (!!_data.gasLimit?.type && _data.gasLimit.type === 'BigNumber')
        {
            gasLimit = utils.formatUnits(_data.gasLimit);
        }

        if (value && typeof value === "string")
        {
            value = utils.formatEther(value);
        }

        const tx = {
            from: address,
            to: _data.to,
            data: _data.data,
            gasLimit: gasLimit || null,
            value: (value || 0).toString(),
            nonce: _data.nonce,
            chainId: _data.chainId,
        };

        const voidSigner = new VoidSigner(address, provider);

        const populatedTx = await voidSigner.populateTransaction(tx);

        const feeData = await provider.getFeeData();

        const gasPrice: BigNumber = (<BigNumber>feeData.gasPrice).mul(125).div(100);

        let feeBN = gasPrice.mul(<BigNumber>populatedTx.gasLimit);

        if (!!tx.value && Number(tx.value) > 0)
        {

            if (BigNumber.isBigNumber(_data.value))
            {
                feeBN = feeBN.add(tx.value);
            }
            else
            {
                feeBN = feeBN.add(utils.parseUnits(tx.value));
            }
        }

        const fee = utils.formatUnits(feeBN);

        return fee;
    }

    public async waitTxConfirmation(_hash: string, address: string, _confirmations: number, _chainId?: ChainId): Promise<string>
    {
        const provider = _chainId ? this.providerFactory.getProvider(_chainId) : this.provider;
        await sleep(15000); //wait to get transaction from blockchain provider

        let tries = 0;
        let tx = await provider.getTransaction(_hash);

        while (!tx)
        {
            if (tries > 5) break;

            await sleep(5000);

            tx = await provider.getTransaction(_hash);

            if (!!tx) break;

            tries++;
        }

        const result = await tx.wait(_confirmations);//Promise.race([this.getNewHash(tx.nonce, address), tx.wait(_confirmations)]);

        if (typeof result === "string")
        {
            const newTx = await provider.getTransaction(_hash);

            await newTx.wait(_confirmations);

            return result;
        }

        return result.transactionHash;
    }


    public async getAllowance(walletAddress_: string, address_: string, addressTo_: string, abi_: any, _chainId?: ChainId): Promise<string>
    {
        const provider = _chainId ? this.providerFactory.getProvider(_chainId) : this.provider;
        let contract = new Contract(address_, abi_, provider);

        const contractResponse = await contract[ 'allowance(address,address)' ](walletAddress_, addressTo_);

        let decimals = 18;

        try
        {
            decimals = await contract[ 'decimals' ]();
        }
        catch (e) { }

        let allowance = utils.formatUnits(contractResponse, decimals);

        return allowance;
    }

    public async approve(amount_: string, walletAddress_: string, address_: string, addressTo_: string, abi_: any, _chainId?: ChainId): Promise<any>
    {
        const provider = _chainId ? this.providerFactory.getProvider(_chainId) : this.provider;
        let contract = new Contract(address_, abi_, provider);

        let decimals = 18;

        try
        {
            decimals = await contract[ 'decimals' ]();
        }
        catch (e) { }

        let value = utils.parseUnits(amount_, decimals);

        const contractTx = await contract.populateTransaction[ 'approve' ](addressTo_, value);

        contractTx.value = utils.parseUnits('0');
        contractTx.from = walletAddress_;

        const voidSigner = new VoidSigner(walletAddress_, provider);

        const tx = await voidSigner.populateTransaction(contractTx);

        return tx;
    }
    //#endregion Public Methods
}

