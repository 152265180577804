export interface IGetAuthUrlParams
{
    redirect_url?: string;
    flow?: string;
}

export enum eFlowMode
{
    WEB2_SIGN_IN = 0,
    WEB2_SIGN_UP = 1,
    WEB3_SIGN_IN = 3,
    WEB2_WEB3_SIGN_IN = 4,
    WEB2_WEB3_SIGN_UP = 5,
    UPGRADE_FROM_GUEST = 6,
    SETUP_MFA = 7,
    TRANSACTION = 10
}

export enum eWalletGetMode
{
    CYBER_WALLET_CREATE = 0,
    CYBER_WALLET_GET = 1,
    WALLET_GET_FAST = 2,
    WALLET_GET_FULL = 3
}