import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWalletRes } from '@common/api/wallets';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class WalletService
{
    private currentWallet$: BehaviorSubject<IWalletRes | undefined> = new BehaviorSubject<IWalletRes | undefined>(undefined);

    constructor(
        private http: HttpClient
    )
    {
    }

    get CurrentWallet$(): Observable<IWalletRes | undefined>
    {
        return this.currentWallet$.asObservable();
    }

    public async getWallet(): Promise<IWalletRes>
    {
        let endpoint = `/api/wallet-get-update`;

        const walletInfo = await firstValueFrom(this.http.get<IWalletRes>(endpoint));

        this.currentWallet$.next(walletInfo);

        return walletInfo;
    }
}
